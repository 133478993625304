<template>
  <div class="pa-4" style="max-width: 1000px">
    <v-row>
      <v-col cols="12" md="9">
        <h1 style="padding-bottom:0px;">
          EisbaumTabelle |
          <span style="color: #d84315"
            >Tierkrankenversicherungen für den Urlaub mit Hund</span
          >
        </h1>

        <br />
        <h3>
          <ul>
            <li>Die besten Krankenversicherungen für den Urlaub mit Hund</li>
            <li>
              Tierkrankenversicherungen für den vorrübergehenden
              Auslandsaufenthalt mit Hund
            </li>
            <li>
              Diese Hundekrankenversicherungen leisten für Behandlungen und OPs
              im Auslands-Urlaub
            </li>
          </ul>
        </h3>
        <br />
        Das erste Mal “Urlaub mit Hund”, da ergeben sich viele Fragen für den
        Hundehalter. Eine Frage, die sich meistens erst kurz vor der Abreise in
        den Urlaub stellt, ist die nach der Hunde-Krankenversicherung.
        <b
          >"Was passiert wenn mein Hund im Urlaub erkrankt oder sich gar schwer
          verletzt?</b
        >
        Welche Hundekrankenversicherungen leisten auch im Ausland für
        tierärztliche Behandlungen oder Operationen? Welche
        Leistungs-Einschränkungen gibt es bei einem vorübergehenden
        Auslandsaufenthalt mit Hunden? Welche Versicherung leistet wenn mein
        Hund im Notfall einen Rücktransport nach Deutschland benötigt?
        EisbaumTabelle vergleich Hundekrankenversicherungen für den Urlaub mit
        Hund.
        <br />
        <br />
        <v-btn
          href="/rechner"
          class="nocaps mb-4"
          style="background-color: #d84315; color: #fff"
          >Hundekrankenversicherung Vergleich starten</v-btn
        >
        <br />
        <br />

        <h3>
          <span style="color: #d84315"
            >Die leistungsstärksten Tierkrankenversicherungen für einen Urlaub
            mit Hund</span
          >
        </h3>
        <br />
        <v-row v-if="tarife">
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="tarife"
              :items-per-page="30"
              :hide-default-footer="true"
              class="elevation-0"
            >
              <template v-slot:item="{ item }">
                <tr v-if="item.preis" style="vertical-align: top;">
                  <td>
                    <a :href="`/Krankenversicherung_Hund/${item.tier_url}`">{{
                      item.tier_title
                    }}</a>
                  </td>
                  <td>
                    <a @click="item.show_edit = !item.show_edit"
                      ><b>{{ item.tier_wert }} %</b></a
                    >

                    <v-dialog
                      v-if="item.show_edit"
                      v-model="item.show_edit"
                      width="888"
                    >
                      <spezial-pop :item="item" type="rechenweg"></spezial-pop>
                    </v-dialog>
                  </td>

                  <td>
                    <b>{{ item.tier_vertrag_ausland }}</b>
                  </td>
                  <td>
                    <b>{{ item.tier_ruecktrans }}</b>
                  </td>
                  <td style="min-width:100px;">
                    <span v-if="item.tier_preis_est == 0">
                      <b>{{ item.preis }} €</b>
                    </span>
                    <span v-if="item.tier_preis_est == 1">
                      ca. {{ item.preis }} €
                      <a
                        @click="item.show_edit_2 = !item.show_edit_2"
                        class="caption"
                        ><br />Wieso ca.?</a
                      >
                      <v-dialog
                        v-if="item.show_edit_2"
                        v-model="item.show_edit_2"
                        width="888"
                      >
                        <spezial-pop
                          :item="item"
                          type="preis_est"
                        ></spezial-pop>
                      </v-dialog>
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <span class="smalltext my-2 ml-2">
              Erstattung ist auf die in Deutschland geltende Gebührenordnung für
              Tierärzte (GOT) begrenzt.</span
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" class="mt-8">
            <h3>
              Ungewohnte Situationen & Unbekannte Risiken | Deshalb macht eine
              Hundekrankenversicherung Sinn
            </h3>
            Urlaub mit einem Hund ist wunderschön und nervenaufreibend zugleich.
            Viele Schwierigkeiten und Risiken werden im Vorfeld vom Hundehalter
            bedacht und abgewendet. Wie zum Beispiel die Frage des richtigen
            Reiseziels. Fliege ich mit dem Flugzeug oder fahre ich mit dem Auto
            in den Urlaub? Buche ich ein Hotel mit Hund oder gehe ich auf den
            tierfreundlichen Campingplatz? Hat mein Hund die notwendigen
            Impfungen und einen EU-Heimtierausweis?<br />
            Doch alle Risiken lassen sich im Urlaub mit Hund nicht vermeiden.
            Man befindet sich in einer ungewohnten Umgebung, die nicht nur für
            den Hund sondern auch für die Hundebesitzerin neu und aufregend ist.
            Man trifft viele fremde Menschen und Hunde, die einem nicht nur
            wohlgesonnen sind. Wird mein Hund von einem streunenden Artgenossen
            gebissen oder bei einem Autounfall verletzt? Frisst er das Falsche
            oder bekommt durch ungewohntes Futter Magenkrämpfe und Durchfall?
            Das sind die Fragen die einen Hundebesitzer vor und während einer
            Reise mit einem Hund beschäftigen.
            <br />
            <br />
            <div id="app">
              <div class="text--primary">
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-card ref="" outlined class="mx-auto pa-1">
                      <span style="color: #d84315"
                        ><b>Unsere Empfehlung:</b></span
                      ><br />
                      Bereiten Sie sich auf unvorhersehbare Risiken vor und
                      vermeiden Sie so hohe Kosten für tiermedizinische
                      Behandlungen oder OPs im Ausland. Schließen Sie für den
                      Urlaub mit Hund eine Krankenversicherung ab, die nicht nur
                      in Deutschland sondern auch im europäischen Ausland oder
                      sogar weltweit leistet.
                    </v-card>
                  </template>
                </v-hover>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <h3>
              Hundekrankenversicherungen | Leistungen für den vorrübergehenden
              Auslandsaufenthalt
            </h3>
            Es lohnt sich Hundkrankenversicherungen umfassend zu vergleichen, da
            diese für vorrübergehende Auslandsaufenthalte, im Rahmen eines
            Urlaubs mit Hund, oft recht unterschiedlich leisten. Manche
            Hundekrankenversicherungen leisten nur im Europäischen Ausland und
            wieder andere leisten auch für einen medizinisch notwendigen
            Rücktransport nach Deutschland.
            <br />
            <a href="/rechner"
              >Vergleichen Sie jetzt die leistungsstärksten
              Hundekrankenversicherungen</a
            >
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="12"> </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <menue-download />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import SpezialPop from "./../base/helper/SpezialPop";
  import MenueDownload from "./../base/helper/MenueDownload";
  import baseMixin from "./../mixins/baseMixin";

  export default {
    name: "index-home",
    components: { SpezialPop, MenueDownload },
    mixins: [baseMixin],
    data: function() {
      return {
        updateSeo: {
          seo_type: 2,
          seo_object_id: 1,
          seo_title: "",
        },
        headers: [
          {
            text: "Testsieger 2024",
            align: "start",
            sortable: false,
            value: "tier_title",
            class: "redground white--text",
          },
          {
            text: "Ø-Erstattung",
            align: "start",
            sortable: true,
            value: "tier_wert",
            class: "greyground",
          },
          {
            text:
              "Auslandskrankenschutz für vorübergehenden Auslandsaufenthalt",
            align: "start",
            sortable: true,
            value: "tier_vertrag_ausland",
            class: "greyground",
          },
          {
            text: "Rücktransport nach Deutschland",
            align: "start",
            sortable: true,
            value: "tier_rueck",
            class: "greyground",
          },

          {
            text: "mtl. Beitrag",
            align: "start",
            sortable: true,
            value: "preis",
            class: "greyground",
          },
        ],
        tarife: false,
        select: {
          type: "hunde",
          preise: "ja",
          number: "array",
        },
      };
    },
    created() {
      this.getTarife();
      this.setTitle();
      //this.testGuest();
    },

    methods: {
      saveReferrer() {
        var string = document.referrer,
          substring = ".google";
        var isGoogle = string.indexOf(substring) !== -1;
        if (isGoogle) {
          this.updateSeo.seo_title = document.title;
          this.$store.dispatch("saveReferrer", this.updateSeo);
        }
      },
      testGuest() {
        this.$log.debug("trachCheck app insert");
        this.$store.dispatch("testGuest").then((resp) => {
          if (resp.data.guest) {
            this.guest = resp.data.guest;
          }
        });
      },
      setTitle() {
        this.$log.debug("title");
        document.title = "Tierkrankenversicherungen für den Urlaub mit Hund";
        let metadesc =
          "Diese Hundekrankenversicherungen leisten für Tier-Behandlungen und Tier-Operationen im Urlaub";
        this.saveReferrer();
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", metadesc);
      },
      getTarife() {
        this.$store
          .dispatch("getTarife", this.select)
          .then((resp) => {
            if (resp.data.tarife) {
              this.tarife = resp.data.tarife;
              document.dispatchEvent(new Event("x-app-rendered"));
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
    },
  };
</script>
